import React from "react";
import "../styles/services.css";

const Content = () => (

  <div style={{ padding: "2rem 2rem 10rem", textAlign: "center" }}>
    <h2 style={{ textAlign: "center", color: "#333333", fontWeight: 900, fontSize: "2rem" }}>
        Services Include:
      </h2>
    <content className="serviceContainer">
    <a className="serviceBox" href="/contact">
        <div>
      <h3>Website Development</h3>
      <p>RunSeanRun can design & build your next website or work with you to improve your current one. </p>
      <p>Enquire Now</p>
      </div></a>
       <a className="serviceBox" href="/contact">
        <div>
      <h3>Digital Marketing</h3>
      <p>RunSeanRun offers SEO, Email Marketing, Landing Page Builds, Dashboard Creation, Facebook Ads, Google Ads and more.</p>
      <p>Enquire Now</p>
      </div></a>
    <a className="serviceBox" href="/contact">
        <div>
      <h3>Consultation & Training</h3>
      <p>RunSeanRun can advise and support your next project or campaign.</p>
      <p>Enquire Now</p>
     </div></a>
      
    </content>
  </div>
);

export default Content;
