import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Content />
  </Layout>
)

export default IndexPage
